<template>
    <dashboard-layout>
        <v-row>
            <v-col>
                <v-card>
                    <v-card-title>
                        <v-row>
                            <v-col>
                                {{ track.title }}
                            </v-col>
                            <v-col class="text-right">
                                <v-btn text :to="{name: 'artist.tracks.index'}">return</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-form>
                            <v-row>
                                <v-col>
                                    <v-text-field
                                        placeholder="Track Title"
                                        label="Track Title"
                                        :error-messages="errors.title"
                                        v-model="track.title"
                                        outlined
                                    />
                                </v-col>
                            </v-row>
                            <v-row v-if="!track.id">
                                <v-col>
                                    <vue2-dropzone
                                        ref="myVueDropzone" id="dropzone"
                                        :options="dropzoneOptions"
                                        @vdropzone-success="handleSuccess"
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col v-if="track.created_at">
                                    <v-text-field v-model="track.created_at" readonly/>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <div id="waveform"></div>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col xl="10">
                                    <v-btn @click="handlePlay" v-show="!isPlaying" :disabled="!wavesurferIsReady">
                                        PLAY
                                    </v-btn>
                                    <v-btn @click="handlePause" v-show="isPlaying" :disabled="!wavesurferIsReady">
                                        PAUSE
                                    </v-btn>
                                    <v-btn @click="save">Save</v-btn>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row v-if="track.id">
            <v-col>
                <v-card>
                    <v-card-title>
                        Plays
                    </v-card-title>
                    <v-card-subtitle>
                        {{ track.artist_track_plays_count }}
                    </v-card-subtitle>
                </v-card>
            </v-col>
        </v-row>
    </dashboard-layout>
</template>

<script>
import Track from "@/models/Track";
import vue2Dropzone from "vue2-dropzone";
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import WaveSurfer from 'wavesurfer.js';
import DashboardLayout from "@/layouts/DashboardLayout";
import {mapMutations} from "vuex";

export default {
    name: "tracks-show",
    components: {DashboardLayout, vue2Dropzone},
    data: function () {
        return {
            isPlaying: false,
            wavesurfer: null,
            wavesurferIsReady: false,
            track: {},
            errors: [],

            dropzoneOptions: {
                url: '/api/upload',
                thumbnailWidth: 250,
                maxFilesize: 20,
                maxFiles: 1,
                headers: {
                    Authorization: null
                },
                params: {
                    directory: '/tracks'
                },
            },

        }
    },
    methods: {
        handleSuccess(file, res) {
            this.track.file_url = res.url
            this.track.file_path = res.path
            this.track.title = this.track.title ? this.track.title : res.original_name
            this.$forceUpdate()
            this.initiateWaveform()
        },
        async save() {
            this.errors = []

            try {
                this.track = await this.track.save()
            } catch (e) {
                this.errors = e.response.data.errors
                return
            }

            if (!this.$route.params.id) {
                await this.$router.push({name: 'artist.tracks.index'})
                return
            }

            this.showSnackBar({color: 'success', timeout: 3000, text: 'Track saved'})
        },
        initiateWaveform() {
            if (!this.track.file_url) {
                return
            }

            this.wavesurfer = WaveSurfer.create({
                container: '#waveform',
                waveColor: 'violet',
                progressColor: 'purple'
            });
            this.wavesurfer.load(this.track.file_url);
            this.wavesurfer.on('ready', () => this.wavesurferIsReady = true);
            this.$forceUpdate()
        },
        handlePlay() {
            this.isPlaying = true;
            this.wavesurfer.play()
        },
        handlePause() {
            this.isPlaying = false;

            if (this.wavesurfer) {
                this.wavesurfer.pause()
            }
        },
        ...mapMutations(['showSnackBar']),
    },
    async mounted() {
        const trackId = this.$route.params.id
        this.track = trackId ? await Track.find(trackId) : new Track();
        this.initiateWaveform()

        const token = localStorage.getItem('auth_token_default')
        this.dropzoneOptions.headers.Authorization = "Bearer " + token
    },
    beforeDestroy() {
        this.handlePause()
    },
}
</script>

<style scoped>

</style>
